<template>
	<div class="traveller" @click="addTraveller">

		<img class="family-icon" src="@/assets/travellers/family-icon.png" alt="Family Icon" />
		
		<div class="right-section">
			{{ text }}
			<img class="add-icon" src="@/assets/travellers/add-icon.png" alt="Add Icon" />
		</div>

	</div>
</template>

<script>
// What happens if we edit a guest (are there trip versions, or just one version of details seeing as it's not linked to a separate user)
// -- What then, if the guest is on two separate trips, and another user edits the guests details for 1 trip (and shouldn't change on the other trip?)
import router from '@/router';

export default {
	props: {
		tripID: {
			type: Number,
			default: null,
		},
		tripItemID: {
			type: Number,
			default: null,
		},
		text: {
			type: String,
			default: "Add new traveller"
		}
	},
	data() {
		return {
		}
	},
	computed: {
		
	},
	methods: {
		addTraveller() {
			// Redirect user to the add page
			router.push({ 
				name: "Add Traveller", 
				params: {
					trip_id: this.tripID,
					trip_item_id: this.tripItemID,
				},
				query: {
					redirect: this.$route.fullPath,
				}
			})
		},
	},
}
</script>

<style scoped>
	.traveller {
		border: 1px solid #E5E5E5;
		border-radius: 10px;
		width: 350px;
		max-width: 100%;
		min-height: 80px;
		display: inline-block;
		text-align: left;
	}

	.traveller .family-icon {
		width: 46px;
		margin-top: 17px;
		margin-left: 21px;
	}

	.traveller .right-section {
		float: right;
		vertical-align: middle;
		margin-top: 23px;
		margin-right: 15px;
		line-height: 33px;
	}
	.traveller .add-icon {
		width: 26px;
		vertical-align: middle;
		margin-left: 10px;
	}
	
</style>